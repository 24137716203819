import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="psionic-prestige-classes"></a><h2>Psionic Prestige Classes
    </h2>
    <a id="thrallherd"></a><h3>THRALLHERD</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/xph_gallery/80459.jpg" style={{
      "fontWeight": "bold"
    }}><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a> <span style={{
      "fontWeight": "bold"
    }}>Hit Die:</span> d4.
    <a id="thrallherd-requirements"></a><h6>Requirements</h6>
To qualify to become a thrallherd, a character must fulfill all the
following criteria.
    <p><span style={{
        "fontWeight": "bold"
      }}>Skills:</span> <a href="skillsAll.html#diplomacy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Diplomacy</a>
4
ranks, <a href="psionicSkills.html#knowledge-psionics" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge (psionics)</a> 8 ranks. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Feat:</span> <a href="psionicFeats.html#inquisitor" style={{
        "color": "rgb(87, 158, 182)"
      }}>Inquisitor</a>.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Psionics:</span> Manifester
level 5th and able to manifest mindlink.</p>
    <h6>Class Skills</h6>
The thrallherd&#8217;s class skills are <a href="psionicSkills.html#autohypnosis" style={{
      "color": "rgb(87, 158, 182)"
    }}>Autohypnosis</a> (Wis), <a href="skillsAll.html#bluff" style={{
      "color": "rgb(87, 158, 182)"
    }}>Bluff</a>
(Cha), <a href="psionicSkills.html#concentration" style={{
      "color": "rgb(87, 158, 182)"
    }}>Concentration </a>(Con), <a href="skillsAll.html#craft" style={{
      "color": "rgb(87, 158, 182)"
    }}>Craft</a>
(any) (Int), <a href="skillsAll.html#diplomacy" style={{
      "color": "rgb(87, 158, 182)"
    }}>Diplomacy</a> (Cha), <a href="psionicSkills.html#knowledge-psionics" style={{
      "color": "rgb(87, 158, 182)"
    }}>Knowledge
(psionics)</a>, <a href="psionicSkills.html#psicraft" style={{
      "color": "rgb(87, 158, 182)"
    }}>Psicraft</a> (Int), and <a href="skillsAll.html#sense-motive" style={{
      "color": "rgb(87, 158, 182)"
    }}>Sense
Motive</a> (Wis).
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each Level:</span>
2 + Int modifier.</p>
    <a id="table-the-thrallherd"></a><span style={{
      "fontWeight": "bold"
    }}>Table: The Thrallherd</span>
    <table style={{
      "width": "100%"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "7%"
          }}>Level</th>
          <th style={{
            "width": "12%"
          }}>Base
Attack Bonus</th>
          <th style={{
            "width": "7%"
          }}>Fort
Save</th>
          <th style={{
            "width": "7%"
          }}>Ref
Save</th>
          <th style={{
            "width": "7%"
          }}>Will
Save</th>
          <th style={{
            "width": "25%"
          }}>Special
          </th>
          <td style={{
            "fontWeight": "bold",
            "verticalAlign": "bottom"
          }}>Powers
Known</td>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+0</td>
          <td>+0</td>
          <td>+2</td>
          <td>Thrallherd
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>-
          </td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+0</td>
          <td>+0</td>
          <td>+3</td>
          <td>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>+1 level of existing
manifesting class
          </td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+1</td>
          <td>+1</td>
          <td>+1</td>
          <td>+3</td>
          <td>Psionic charm
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>+1 level of existing
manifesting class
          </td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+2</td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
          <td>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>+1 level of existing
manifesting class
          </td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+2</td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
          <td>Psionic dominate
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>+1 level of existing
manifesting class
          </td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+3</td>
          <td>+2</td>
          <td>+2</td>
          <td>+5</td>
          <td>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>+1 level of existing
manifesting class
          </td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+3</td>
          <td>+2</td>
          <td>+2</td>
          <td>+5</td>
          <td>Greater dominate
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>+1 level of existing
manifesting class
          </td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+4</td>
          <td>+2</td>
          <td>+2</td>
          <td>+6</td>
          <td>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>+1 level of existing
manifesting class
          </td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+4</td>
          <td>+3</td>
          <td>+3</td>
          <td>+6</td>
          <td>Superior dominate
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>+1 level of existing
manifesting class
          </td>
        </tr>
        <tr>
          <td className="last-row">10th</td>
          <td className="last-row">+5</td>
          <td className="last-row">+3</td>
          <td className="last-row">+3</td>
          <td className="last-row">+7</td>
          <td className="last-row">Twofold master
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>-
          </td>
        </tr>
      </tbody>
    </table>
    <a id="thrallherd-class-features"></a><h6>Class Features</h6>
All the following are class features of the thrallherd prestige class.
    <p><span style={{
        "fontWeight": "bold"
      }}>Weapon and Armor Proficiency:</span>
Thrallherds gain no proficiency with any weapon or armor.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Powers Known:</span> At every
level from 2nd through 9th, a thrallherd gains additional power points
per day and access to new powers as if she had also gained a level in
whatever manifesting class she belonged to before she added the
prestige class. She does not, however, gain any other benefit a
character of that class would have gained (bonus feats, metapsionic or
item creation feats, psicrystal special abilities, and so on). This
essentially means that she adds the level of thrallherd to the level of
whatever manifesting class the character has, then determines power
points per day, powers known, and manifester level accordingly.
If a character had more than one manifesting class before she became a
thrallherd, she must decide to which class she adds the new level of
thrallherd for the purpose of determining power points per day, powers
known, and manifester level.</p>
    <a id="thrallherd-thrallherd"></a><p><span style={{
        "fontWeight": "bold"
      }}>Thrallherd (Ex):</span> A
thrallherd who has just entered the class sends out a subtle psychic
call for servants, and that call is answered. Essentially, the
character gains something akin to the <a href="featsAll.html#leadership" style={{
        "color": "rgb(87, 158, 182)"
      }}>Leadership</a>
feat, but with some
important differences.
Those who answer a thrallherd&#8217;s call are not referred to as cohorts and
followers, but rather as thralls and believers, respectively. They do
not appear because they admire the character and want to serve her, but
because a hidden psychic resonance connects the thrallherd and her
servants.
As with the Leadership feat, a thrallherd has a Leadership score that
determines the highest-level thrall and believers she can attract. A
thrallherd&#8217;s Leadership score is equal to her character level + her Cha
modifier + her thrallherd level. (Note that her thrallherd level is
counted twice.) This score is not affected by any of the modifiers
mentioned in the Leadership feat.
As with the Leadership feat, the called thrall&#8217;s level is limited, even
if the character&#8217;s Leadership score would indicate a higher-level
thrall. Unlike with the Leadership feat, the level limit of a thrall is
equal to the character&#8217;s total level minus 1 (not level minus 2, as is
true for cohorts).
A thrallherd cannot take the Leadership feat; if the character already
has it, the feat is lost and replaced by this ability; those who were
previously cohorts and followers go their separate ways, and those who
are more mentally pliable show up later to take up roles as thralls and
believers. A thrallherd&#8217;s first thrall and believers arrive within 24
hours of her entry into this class; likewise, lost thralls and
believers are replaced within 24 hours.
Use the following table instead of the one with the Leadership feat to
determine the level of a thrallherd&#8217;s thrall and the number of
believers of various levels a thrallherd can attract.
    </p>
    <a id="table-thrallherd-thrall-level"></a><table style={{
      "marginTop": "0.5em"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th rowSpan="2" style={{
            "width": "100px"
          }}>Leadership
Score</th>
          <th rowSpan="2" style={{
            "width": "75px"
          }}>Thrall
Level</th>
          <th colSpan="6">- Number of Believers by Level -</th>
        </tr>
        <tr>
          <th>1st</th>
          <th>2nd</th>
          <th>3rd</th>
          <th>4th</th>
          <th>5th</th>
          <th>6th</th>
        </tr>
        <tr className="odd-row">
          <td>1 or lower</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>2</td>
          <td>1st</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>3</td>
          <td>2nd</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>4</td>
          <td>3rd</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>5</td>
          <td>3rd</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>6</td>
          <td>4th</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>7</td>
          <td>5th</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>8</td>
          <td>5th</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>9</td>
          <td>6th</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>10</td>
          <td>7th</td>
          <td>5</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>11</td>
          <td>7th</td>
          <td>6</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>12</td>
          <td>8th</td>
          <td>8</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>13</td>
          <td>9th</td>
          <td>10</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>14</td>
          <td>10th</td>
          <td>15</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>15</td>
          <td>10th</td>
          <td>20</td>
          <td>2</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>16</td>
          <td>11th</td>
          <td>25</td>
          <td>2</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>17</td>
          <td>12th</td>
          <td>30</td>
          <td>3</td>
          <td>1</td>
          <td>1
          </td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>18</td>
          <td>12th</td>
          <td>35</td>
          <td>3</td>
          <td>1</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>19</td>
          <td>13th</td>
          <td>40</td>
          <td>4</td>
          <td>2</td>
          <td>1</td>
          <td>1</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>20</td>
          <td>14th</td>
          <td>50</td>
          <td>5</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>21</td>
          <td>15th</td>
          <td>60</td>
          <td>6</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>1</td>
        </tr>
        <tr>
          <td>22</td>
          <td>15th</td>
          <td>75</td>
          <td>7</td>
          <td>4</td>
          <td>2</td>
          <td>2</td>
          <td>1</td>
        </tr>
        <tr className="odd-row">
          <td>23</td>
          <td>16th</td>
          <td>90</td>
          <td>9</td>
          <td>5</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
        </tr>
        <tr>
          <td>24</td>
          <td>17th</td>
          <td>110</td>
          <td>11</td>
          <td>6</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
        </tr>
        <tr className="odd-row">
          <td>25 or higher</td>
          <td>17th</td>
          <td>135</td>
          <td>13</td>
          <td>7</td>
          <td>4</td>
          <td>2</td>
          <td>2</td>
        </tr>
      </tbody>
    </table>
    <a id="thrallherd-psionic-charm"></a><p><span style={{
        "fontWeight": "bold"
      }}>Psionic Charm (Ex):</span> At
3rd level, a thrallherd adds <a href="psionicPowersAtoC.html#charm-psionic" style={{
        "color": "rgb(87, 158, 182)"
      }}>psionic charm</a> to her powers
known (if she
doesn&#8217;t already know it). Once per day, she can manifest psionic charm
at a reduced power point cost. The cost of psionic charm is reduced by
the thrallherd&#8217;s level, to a minimum of 1 power point. The effect of
this power is still restricted by the thrallherd&#8217;s manifester level.</p>
    <a id="thrallherd-psionic-dominate"></a><p><span style={{
        "fontWeight": "bold"
      }}>Psionic Dominate (Ex):</span>
At 5th level, a thrallherd adds <a href="psionicPowersDtoF.html#dominate-psionic" style={{
        "color": "rgb(87, 158, 182)"
      }}>psionic dominate</a> to her powers
known
(if she doesn&#8217;t already know it) Once per day, she can manifest psionic
dominate at a reduced power point cost. The cost of psionic dominate is
reduced by the thrallherd&#8217;s level, to a minimum of 1 power point. The
effect of this power is still restricted by the thrallherd&#8217;s manifester
level.</p>
    <a id="thrallherd-greater-dominate"></a><p><span style={{
        "fontWeight": "bold"
      }}>Greater Dominate (Ex):</span>
At 7th level and higher, a thrallherd does not have to pay 2 additional
power points when she augments psionic dominate to affect animals, fey,
giants, magical beasts, and monstrous humanoids. This reduced point
cost does not increase the save DC of the power as if she had spent the
additional power points.</p>
    <a id="thrallherd-superior-dominate"></a><p><span style={{
        "fontWeight": "bold"
      }}>Superior Dominate (Ex):</span>
At 9th level, a thrallherd does not have to pay 4 additional power
points when she augments psionic dominate to affect aberrations,
dragons, elementals, and outsiders (in addition to the creature types
mentioned in the greater dominate ability). This reduced point cost
does not increase the save DC of the power as if she had spent the
additional power points.</p>
    <a id="thrallherd-twofold-master"></a><p><span style={{
        "fontWeight": "bold"
      }}>Twofold Master (Ex):</span>
At 10th level, a thrallherd can add a second thrall to her herd. This
second thrall&#8217;s maximum level is equal to the thrallherd&#8217;s level minus
2, even if her Leadership score would indicate a higher-level thrall. </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      